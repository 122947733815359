/** @jsxImportSource @emotion/react */

import { MouseEventHandler, ReactNode } from 'react';
import { getTypographyStyle } from './utils-typography.module.style';

export type UtilsTypographyProps = {
  type: string;
  children: ReactNode;
  custom_style?: any;
  color: string;
  inline?: boolean;
  onClick?: MouseEventHandler;
  bold?: boolean;
  key?: string | number;
  className?: string;
  opacity?: number;
  hover_color?: string;
  override_font?: string;
  semi_bold?: boolean;
  underline?: boolean;
  alt?: string;
  id?: string;
  strike?: boolean;
  extra_bold?: boolean;
  regular?: boolean;
  onMouseOver?: MouseEventHandler;
};

export function Typography(props: UtilsTypographyProps) {
  return (
    <div
      key={props.key}
      className={props.className}
      css={[getTypographyStyle(props), props.custom_style]}
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
      title={props.alt}
      id={props.id}
      aria-label={props.alt}
    >
      {props.children}
    </div>
  );
}
