/* eslint-disable-next-line */

export const Colors: { [unit: string]: string } = {
  black: '#000000',
  nero: '#2D2D2D',
  night_rider: '#333333',
  transparent: 'transparent',
  white: '#FFFFFF',
  white_60: 'rgba(255, 255, 255, 0.6)',
  heavy_metal: '#424038',
  heavy_metal_10: 'rgba(66, 64, 56, 0.1)',
  heavy_metal_30: 'rgba(66, 64, 56, 0.3)',
  nobel: '#999999',
  gun_powder: '#4E4E50',
  whisper: '#F9F6F9',
  white_smoke: '#F9F9F9',
  white_smoke_home: '#EFEFEF',
  nobel_10: 'rgba(153, 153, 153, 0.1)',
  green: '#008000',
  torch_red: '#FF0323',
  very_light_grey: '#CCCCCC',
  dark_grey: '#B4B4B4',
  jungle_green: '#25AE88',
  orange_peel: '#FC9D04',
  shady_lady: '#979797',
  dim_grey: '#666666',
  dim_grey_20: 'rgba(102, 102, 102, 0.2)',
  white_lilac: '#F6F5F7',
  valhalla: '#282C3F',
  dark_tangerine: '#FFA412',
  dark_charcoal: '#333333',
  gainsboro: '#DCDCDC',
  purple_heart: '#6F28A6',
  purple_heart_10: 'rgba(111, 40, 166, 0.1)',
  lavender: '#F0E4F9',
  lavender_30: 'rgba(240, 228, 249, 0.3)',
  off_yellow: '#FCF5EB',
  cerise: '#D12064',
  cerise_5: 'rgba(209, 32, 100, 0.05)',
  purple_blue_gradient_10: 'linear-gradient(92.38deg, rgba(111, 40, 166, 0.1) 11.32%, rgba(0, 73, 183, 0.1) 100.33%)',
  affair_dark: '#634D74',
  scampi: '#7E5E97',
  cream_brulee: '#FFE78D',
  lime_green: '#34BA46',
  charcoal: '#4D4D4D',
  papaya_whip: '#FFF0D8',
  lavender_blush: '#FFE3ED',
  misty_rose: '#FFDFD9',
  saddle_brown: '#583600',
  indigo: '#2C004E',
  cedar_wood_finish: '#6A1200',
  laser_lemon: '#FFFA53',
  silver_chalice: '#B1B1B1',
  grape: '#4B4156',
  dusty_gray_10: 'rgba(156, 156, 156, 0.1)',
  silver: '#BABABA',
  prussian_blue: '#140025',
  mortar: '#5B5B5B',
  gallery: '#EEEEEE',
  matterhorn: '#505050',
  jagger_90: 'rgba(60, 43, 73, 0.9)',
  roman: '#DF5D60',
  sea_shell: '#F1F1F1',
  midnight_express: '#1F2937',
  tangaroa: '#1E293B',
  navy: '#000080',
  cornflower_blue: '#000048',
  bahia: '#84CC16',
  persian_indigo_70: 'rgba(43, 12, 67, 0.7)',
  link_water: '#D1D5DB',
  tyrian_purple: '#810933',
  evening_sea: '#235B4C',
  mint_cream: '#EDFFFA',
  olive: '#724600',
  seashell: '#FFEDE3',
  brown: '#8B5600',
  tequila: '#FFE7C0',
  pesto: '#796D2D',
  travertine: '#FFFDEA',
  metallic_bronze: '#533F1E',
  sisal: '#DACFBF',
  bush: '#104335',
  frosted_mint: '#D8FFF4',
  oxford_blue: '#374151',
  dew: '#F6FFFD',
  jaguar_90: 'rgba(39, 39, 42, 0.9)',

  // Theme Colors
  black_pearl: '#1A2123',
  mine_shaft: '#363E40',
  california: '#EB8933',
  cinder: '#212A2D',
  brown_orange_gradient: 'linear-gradient(140.63deg, #FE9940 27.97%, #AE5609 121.71%)',
  whisper_shop: '#F0E6E6',

  pattens_blue: '#EEF4F9',
  quarter_spanish_white: '#EBDDD0',
  quarter_spanish_white_30: 'rgba(235, 221, 208, 0.3)',
  polo_blue: '#91B2D0',

  old_rose: '#B32742',

  pink_purple_gradient: 'linear-gradient(92.38deg, #6F28A6 11.32%, #0049B7 100.33%)',

  panache: '#EEF5E2',
  half_baked: '#539383',

  seal_brown: '#270D12',

  bordeaux: '#421820',
  red_berry: '#7B1D2F',
  cranberry: '#D94663',

  info_blue: '#0049B7',
  cyprus_affair_gradient: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(139.48deg, #0D324D 23.5%, #7F5A83 100%)',
  east_side: '#BE94C2',
  affair: '#7F5A83',
  cyprus: '#0D324D',
  medium_wood: '#A48564',
  solitude: '#FBFCFD',
  tulip: '#FA7A8A',
  cyan_lime_green: '#047857',
  dark_spring_green: '#15803D',
  cultured: '#F5F5F5',
  honeydew: '#ECFDF5',
  magnolia: '#FAF5FF',
  east_bay: '#475569',
  orange: '#F59E0B',
  mango_tango: '#D97706',
  argent: '#BFBFBF',
  ghost_white: '#F8FAFC',
  davys_grey: '#52525B',
  magic_mint: '#ADEBCD'
};
