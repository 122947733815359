
export const DIMENSIONS = {
  px_0: '0px',
  px_1: '1px',
  px_2: '2px',
  px_4: '4px',
  px_6: '6px',
  px_8: '8px',
  px_12: '12px',
  px_14: '14px',
  px_16: '16px',
  px_18: '18px',
  px_20: '20px',
  px_24: '24px',
  px_28: '28px',
  px_32: '32px',
  px_36: '36px',
  px_40: '40px',
  px_44: '44px',
  px_48: '48px',
  px_52: '52px',
  px_56: '56px',
  px_60: '60px',
  px_64: '64px',
  px_68: '68px',
  px_72: '72px',
  px_80: '80px',
  px_84: '84px',
  px_88: '88px',
  px_100: '100px',
  px_120: '120px',
  px_140: '140px',
  px_160: '160px'
};
