import { css } from '@emotion/react';

import { Colors } from '@vc-workspace/utils-colors';
import { UtilsTypographyProps } from './utils-typography';

const COMMON_STYLE = css({
  textDecoration: 'none',
  '& > p, h1, h2, h3, h4, h5, h6, span, a, address, i': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
    color: 'inherit !important',
    textDecoration: 'none',
  },
});

const BASE_STYLES = [
  {
    type: 'Heading1',
    styles: css({
      fontSize: '36px',
    }),
  },
  {
    type: 'Heading2',
    styles: css({
      fontSize: '32px',
    }),
  },
  {
    type: 'Heading3',
    styles: css({
      fontSize: '28px',
    }),
  },
  {
    type: 'Headline_H1',
    styles: css({
      fontSize: '24px',
    }),
  },
  {
    type: 'Headline_H1_22',
    styles: css({
      fontSize: '22px',
    }),
  },
  {
    type: 'Headline_H2',
    styles: css({
      fontSize: '20px',
    }),
  },
  {
    type: 'Headline_H3',
    styles: css({
      fontSize: '18px',
    }),
  },
  {
    type: 'SubHeading1',
    styles: css({
      fontSize: '16px',
    }),
  },
  {
    type: 'Body1',
    styles: css({
      fontSize: '14px',
    }),
  },
  {
    type: 'Body2',
    styles: css({
      fontSize: '12px',
    }),
  },
  {
    type: 'Caption',
    styles: css({
      fontSize: '10px',
    }),
  },
];

export function getTypographyStyle(
  {
    type,
    color,
    onClick,
    bold,
    inline,
    opacity,
    hover_color,
    override_font,
    semi_bold,
    underline,
    strike,
    extra_bold,
    regular
  }: UtilsTypographyProps) {
  return [
    COMMON_STYLE,
    BASE_STYLES.filter((style) => style.type === type)[0].styles,
    color
      ? css({
          color: Colors[color] || color,
        })
      : null,
    onClick
      ? css({
          cursor: 'pointer',
        })
      : null,
    bold
      ? css({
          fontWeight: 700,
        })
      : null,
    semi_bold
      ? css({
          fontWeight: 600,
        })
      : null,
    inline
      ? css({
          display: 'inline-block',
        })
      : null,
    opacity
      ? css({
          opacity: opacity,
        })
      : null,
    hover_color
      ? css({
          '&:hover': {
            color: Colors[hover_color] || hover_color,
            cursor: 'pointer',
          },
        })
      : null,
    override_font
      ? css({
          fontFamily: `${override_font} !important`,
        })
      : null,
    underline
      ? css({
          textDecoration: 'underline',
        })
      : null,
    strike
      ? css({
          textDecoration: 'line-through',
        })
      : null,
    extra_bold
      ? css({
          fontWeight: 800,
        })
      : null,
    regular
      ? css({
        fontWeight: 400
      })
      : null,
  ];
}
